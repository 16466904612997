body {
    font-size: 0px;
    width:100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align:center;
}
#web{
    width:100%;
    font-size: 0px;
    overflow: hidden;
    right:0;
    position:relative;
    @include transition(all,0.6s,ease,0s);
}
.content {
    max-width:1170px;
    width:100%;
    position:relative;
    box-sizing:border-box;
    display: inline-block;
    padding: 0 123px;
    font-size: 0px;
    @media(max-width:1200px){
    padding:0 100px;
    }
    @media(max-width:600px){
    padding:0 44px;
    }
}
a {
    text-decoration:none;
}
em {
    font-style:italic;
}
//animacje
.mask {
    @include position(absolute,1,0,0,0,0);
}
.transition{
    @include transition(all,0.7s,ease,0s);
}
//wysokość szerokość
.w100 {
    width:100%;
}
.h100 {
    height:100%;
}
// box
.border-box {
    box-sizing:border-box;
}
.padding15 {
    padding:15px;
}
.padding0 {
    padding:0;
}
.inline-block {
    display:inline-block;
}
.block {
    display:block;
}
//pozycja
.relative {
    position:relative;
}
.absolute {
    position:absolute;
}
// align
.left {
    text-align:left;
}
.center {
    text-align:center;
}
.right {
    text-align:right;
}
// v-align
.topp {
    vertical-align:top;
}
.bottom {
    vertical-align:bottom;
}
.middle {
    vertical-align:middle;
}
// anchor
.decoration-none {
    text-decoration: none;
}
// transform {
.uppercase{
    text-transform:uppercase;
}
// Background-image
.bgimg {
    @include background-image;
    height:100%;
}
// Ghost
.ghost-element {
    &:before {
        @include ghost-element;
        height: 100%;
    }
}
.ghost-content {
    @include ghost-content
}
.translateX{
    @include transform(translateX(-50%));
}
.translateY{
    @include transform(translateY(-50%));
}
.translateXY{
    @include transform(translate(-50%,-50%));
    @include position(absolute,2,50%,null,null,50%);
}
a {
    @include transition(all,0.7s,ease,0s);
}
