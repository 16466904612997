.top {
    width:100%;
    @include position(fixed,1003,0,null,null,null);
    white-space: nowrap;
    text-align:center;
    @include transition(all,0.6s,ease,0s);
    .bg {
      position:absolute;
      top:0;
      transform:translateX(-50%);
      left:50%;
      min-width:1920px;
      width:100%;
    }
    .content {
        height:100%;
        position:relative;
        max-width:1680px;
        .logo {
          position:absolute;
          left:40px;
          z-index:2;
          top:25px;
          @media(max-width:1280px){
          left:20px;
          }
          @media(max-width:420px){
            top:30px;
            img {
              width:150px;
            }
          }
        }
        #menu {
          position:absolute;
          right:130px;
          top:40px;
          ul {
            li:last-child {
              a {
                color:#E1B401;
              }
            }
            li {
              display:inline-block;
              padding:0 30px;
              @media(max-width:1600px){
                padding:0 13px;
              }

              a {
                @include font(msblack,15px,#707070,1);
                text-transform: uppercase;
                @media(max-width:1440px){
                font-size:14px;
                }
              }
            }
            .active {
              a {
                position:relative;
                padding-bottom:4px;
                &:before {
                  content:"";
                  left:0;
                  right:0;
                  bottom:0;
                  height:3px;
                  background:#E1B401;
                  position:absolute;
                }
              }
            }
          }
        }
        .social-media{
          position:absolute;
          right:40px;
          top:36px;
          @media(max-width:1280px){
            right:70px;
          }
          ul {
            li {
              display:inline-block;
              &:nth-child(1){
                margin-right:20px;
              }
              a {
                img {
                  width:22px;
                }
              }
            }
          }
        }
    }
}
