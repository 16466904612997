body {
  background:#fff;
  position:relative;
  overflow-x: hidden;
}
#page {
  overflow:hidden;
}
section {

}
.content {
  max-width:1280px;
  box-sizing:border-box;
  padding:0 40px;
  text-align:left;
  @media(max-width:1200px){
    padding:0 20px;
  }
}
.header{
  position:relative;
  padding-bottom:20px;
  h1,h2,h3,h4,h5,h6 {
    @include font(msblack,51px,#000,1);
    @media(max-width:1024px){
      font-size:36px;
    }
    @media(max-width:420px){
      font-size:30px;
    }
  }
  &:before {
    content:"";
    width:270px;
    height:3px;
    background:#E1B401;
    position:absolute;
    bottom:0;
    left:50%;
    @include transform(translateX(-50%));
  }
}
.pheader{
  margin-top:20px;
  p {
    @include font(msblack,21px,#000,1);
    @media(max-width:1024px){
    font-size:18px;
    }
    @media(max-width:420px){
    font-size:17px;
    }
  }
}
.desc {
  p {
    @include font(msregular,15px,#000,1.2);
  }
  ul {
    li{
      @include font(msregular,15px,#000,1.2);
    }
  }
}
.button {
  span,a {
    display:inline-block;
    background:#E1B401;
    border-radius:19px;
    padding:10px 40px;
    @include font(msblack,17px,#fff,1);
    cursor:pointer;
  }
}
#banner{
  width:100%;
  height:100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index:0;
  overflow: hidden;
  @media(max-width:800px){
    background:none!important;
    height:inherit;
    margin-top:100px
  }
  .mobile_image{
    display:none;
    @media(max-width:800px){
      display:inline-block;
    }
    .ks {
      position: absolute;
      top: 30px;
      left: 50%;
      bottom: 0;
      height: 90%;
      @include transform(translateX(-50%));
    }
  }

  .box {
    @media(max-width:800px){
      display:none;
    }
    position:absolute;
    left:45%;
    top:50%;
    right:8%;
    @include transform(translateY(-50%));
    text-align:left;
    @media(max-width:1280px){
    left:30%;
    right:5%;
    }
    @media(max-width:420px){
      left:20px;
      right:20px;
    }
    .big {
      @include font(msblack,51px,#000000,1.2);
      position:relative;
      @media(max-width:1280px){
      font-size:33px;
      }
      @media(max-width:420px){
      font-size:22px;
      }
      &:before {
        content:"";
        left:0;
        bottom:-20px;
        width:270px;
        height:3px;
        background:#707070;
        position: absolute;
      }
    }
    .small {
      margin-top:50px;
      @include font(msregular,36px,#000000,1.2);
      @media(max-width:1280px){
      font-size:22px;
      }
      @media(max-width:420px){
      font-size:18px;
      }
    }
  }
}

#ksiazka {
  position:relative;
  z-index:1;
  text-align:center;
  .image {
    position:absolute;
    top:0;
    @include transform(translateY(-80%));
    left:0;
    min-width:1920px;
    width:100%;
    .bgu {
      min-width:1920px;
      width:100%;
    }
  }
  .content {
    text-align:center;
    .box {
      display:none;
      border-bottom:3px solid #E1B401;
      margin-bottom:40px;
      padding-bottom:30px;
      @media(max-width:800px){
        display:inline-block;
        .big {
          @include font(msblack,51px,#000000,1.2);
          position:relative;
          @media(max-width:800px){
          font-size:22px;
          }
          &:before {
            content:"";
            left:50%;
            @include transform(translateX(-50%));
            bottom:-10px;
            width:270px;
            height:3px;
            background:#707070;
            position: absolute;
          }
        }
        .small {
          margin-top:30px;
          @include font(msregular,36px,#000000,1.2);
          @media(max-width:800px){
          font-size:17px;
          }

        }
      }
    }
    .desc {
      margin-top:90px;
      @media(max-width:1024px){
      margin-top:40px;
      }
    }
  }
}

#fragmenty {
  margin-top:130px;
  @media(max-width:1024px){
  margin-top:80px;
  }
  position:relative;
  z-index:2;
  text-align:center;
  .content {
    text-align:center;
    .boxes {
      margin-top:130px;
      @media(max-width:1024px){
      margin-top:40px;
      }
      .box {
      @include rwd-box(33.33%,0,40px,0,40px);
      vertical-align: top;
      @media(max-width:800px){
        width:100%;
        padding:15px 0;
      }
        .image {

        }
        .title {
          margin-top:40px;
          h2 {
            @include font(msblack,20px,#000,1);
          }
        }
        .txt {
          margin-top:25px;
          p {
            @include font(msregular,15px,#000,1.2);
            strong{
              font-family:msbold;
            }
          }
        }
        .button {
          margin-top:25px;
        }
      }
    }
  }
}




#o-autorach {
  position:relative;
  z-index:3;
  text-align:center;
  background:#F4F4F4;
  padding-bottom:100px;
  margin-top:300px;
  @media(max-width:1024px){
  margin-top:200px;
  padding-bottom:0;
  }

  .bgu {
    position:absolute;
    top:0;
    @include transform(translate(-50%,-80%));
    left:50%;
    min-width:1920px;
    width:100%;
  }
  .bgd {
    position:absolute;
    bottom:0;
    @include transform(translate(-50%,80%));
    left:50%;
    min-width:1920px;
    width:100%;
  }
  .header, .pheader{
    padding-left:20px;
    padding-right:20px;
  }
  .content {
    text-align:center;
    .boxes {
      margin-top:120px;
      @media(max-width:1024px){
      margin-top:40px;
      }
      .box {
        @include rwd-box(33.33%,15px,45px,15px,45px);
        vertical-align: top;
        text-align:center;
        @media(max-width:1024px){
          width:50%;
        }
        @media(max-width:800px){
          width:100%;
          padding:15px 0px;
        }
        .image {
        }
        .title {
          margin-top:40px;
          h2 {
            @include font(msblack,20px,#000,1);
          }
        }
        .txt {
          margin-top:25px;
          p {
            @include font(msregular,15px,#000,1.2);
            strong{
              font-family:msbold;
            }
          }
        }
        .mail {
          margin-top:25px;
          p {
            @include font(msblack,15px,#000,1);
          }
        }
      }
    }
  }
}
#recenzje {
  position:relative;
  z-index:4;
  margin-top:250px;
  margin-bottom:300px;
  @media(max-width:1024px){
  margin-top:200px;
  margin-bottom:200px;
  }
  .content{
    max-width:1920px;
    text-align:center;
    .slider {
      margin-top:100px;
      overflow:hidden;
      @media(max-width:1024px){
      margin-top:40px;
      }
      .box {
        @include rwd-box(50%,0,110px,0,110px);
        @media(max-width:1440px){
          padding:0 20px;
        }
        img {}
        .txt {
          margin-top:30px;
          p {
             @include font(msregular,18px,#000,1);
             @media(max-width:800px){
               font-size:15px;
             }
          }
        }
        .autor {
          margin-top:25px;
          p {
            @include font(msblack,18px,#000,1);
            @media(max-width:800px){
              font-size:15px;
            }
          }
        }
      }
      .slick-dots {
        margin-top:45px;
        li {
          display:inline-block;
          padding:8px 17px;
          button {
            width:50px;
            height:5px;
            background:#000;
            font-size:0;
            border:0;
            cursor:pointer;
          }
        }
        .slick-active {
          button {
            background:#E1B401;
          }
        }
      }
    }
  }
}
#gdzie-kupic{
  position:relative;
  z-index:5;
  text-align:center;
  background:#F4F4F4;
  padding-bottom:30px;
  .bgu {
    position:absolute;
    top:0;
    @include transform(translate(-50%,-80%));
    left:50%;
    min-width:1920px;
    width:100%;
  }
  .bgd {
    position:absolute;
    bottom:0;
    @include transform(translate(-50%,80%));
    left:50%;
    min-width:1920px;
    width:100%;
  }
  .header, .pheader{
    padding-left:20px;
    padding-right:20px;
  }
    .boxes {
      margin-top:30px;
      max-width:1920px;
      display:inline-block;
      width:100%;
    .box:nth-child(1){
      width:36%;
      display:inline-block;
      box-sizing:border-box;
      padding-right:1%;
      vertical-align:middle;
      img {
        width:100%;
      }
      @media(max-width:580px){
        display:none;
      }
    }
    .box:nth-child(2){
      width:64%;
      display:inline-block;
      box-sizing:border-box;
      padding-right:1%;
      vertical-align:middle;
      @media(max-width:580px){
      width:100%;
      padding:0;
      }
      .image {
        @include rwd-box(33.33%,25px,20px,25px,20px);
        vertical-align: middle;
        @media(max-width:420px){
          width:50%;
        }
      }
    }
  }
}



#powiazane-serwisy{
  position:relative;
  z-index:6;
  margin-top:300px;
  @media(max-width:1024px){
  margin-top:200px;
  }
  .bg {
    position:absolute;
    left:50%;
    top:50%;
    @include transform(translate(-50%,-50%));
    max-width:1920px ;
    width:90%;
  }
  .header, .pheader{
    padding-left:20px;
    padding-right:20px;
  }
  .content {
    text-align:center;
    .boxes {
      margin-top:120px;
      @media(max-width:1280px){
        margin-top:40px;
      }
      .box {
        max-width:360px;
        padding:0 55px;
        vertical-align: top;
        display:inline-block;
        text-align:center;
        @media(max-width:1024px){
          width:100%;
          max-width:inherit;
          padding:30px 0;
          box-sizing:border-box;
        }
        .image {
          width:182px;
          height:182px;
          background:#E1B401;
          border-radius:50%;
          display:inline-block;
          img {
            margin-top:16px;
          }
        }
        .title {
          margin-top:40px;
          h5 {
            @include font(msblack,20px,#000,1);
          }
        }
        .txt {
          margin-top:25px;
          p {
            @include font(msregular,15px,#000,1.2);
            strong{
              font-family:msbold;
            }
          }
        }
        .button {
          margin-top:25px;
          a {

          }
        }
      }
    }
  }
}
#kontakt {
  background:#F4F4F4;
  padding-bottom:140px;
  position:relative;
  z-index:7;

  margin-top:300px;
  @media(max-width:1024px){
  margin-top:200px;
  padding-bottom:80px;
  }
  .bg {
    position:absolute;
    top:0;
    @include transform(translate(-50%,-80%));
    left:50%;
    min-width:1920px;
    width:100%;
  }
  .content {
    text-align:center;
    .email {
      margin-top:70px;
      p {
        padding-bottom:10px;
        display:inline-block;
        @include font(msbold,20px,#000,1);
        border-bottom:3px solid #E1B401;
      }
    }
    .social {
      margin-top:70px;
      @include font(msblack,21px,#000,1);
      .social-media{
        display:inline-block;
        margin-top:50px;
        padding-bottom:20px;
        border-bottom:3px solid #E1B401;
        ul {
          li {
            display:inline-block;
            padding:0 15px;
            a {

            }
          }
        }
      }
    }
  }
}
#footer {
  position:relative;
  z-index:8;
  .bg {
    position:absolute;
    top:0;
    @include transform(translate(-50%,-35%));
    left:50%;
    min-width:1920px;
    width:100%;
  }
  .content {
    text-align:center;
    .logo {

    }
    .footer-menu{
      margin-top:50px;
      margin-bottom:50px;
      ul {
        li {
          display:inline-block;
          padding:10px 40px;
          @media(max-width:1280px){
          padding:10px 15px;
          }
            a{
              @include font(mslight,13px,#707070,1);
            }
          }
        }
    }
    .copyright{
      margin-bottom:18px;
      p {
        @include font(msbold,13px,#707070,1);
      }
    }
    .privacy_policy{
      margin-bottom:18px;
      a {
        @include font(msbold,13px,#707070,1);
      }
    }
  }
}
#cookies-message {
  display:none;
    padding: 10px 15px;
    box-sizing:border-box;
    border-top: 1px solid #15151f;
    text-align: center;
    position: fixed;
    bottom: 0px;
    background: #fff;
    width: 100%;
    z-index: 999;
    p {
      @include font(msr,13px,#000,1);
      padding-right:150px;
      a{
        color:#E1B401;
      }
    }
    #accept-cookies-checkbox{
      display:inline-block;
      background:#E1B401;
      border-radius:5px;
      padding:17px 19px;
      @include font(msbold,14px,#fff,1);
      cursor:pointer;
      position:absolute;
      right:0;
      top:50%;
      @include transform(translateY(-50%));
    }
}
