@font-face {
    font-family: 'msbold';
    src: url('../fonts/montserrat-bold-webfont.eot');
    src: url('../fonts/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/montserrat-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'msblack';
    src: url('../fonts/montserrat-black-webfont.eot');
    src: url('../fonts/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-black-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-black-webfont.woff') format('woff'),
         url('../fonts/montserrat-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'mslight';
    src: url('../fonts/montserrat-light-webfont.eot');
    src: url('../fonts/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-light-webfont.woff') format('woff'),
         url('../fonts/montserrat-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'msregular';
    src: url('../fonts/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
