//BURGER


.burger-menu {
    @include position(absolute,1002,31px,20px,null,null);
    @include transition(all,0.7s,ease,0s);
    background-color: transparent;
    .burger-click-region {
        position: relative;
        width: 30px;
        height: 30px;
        cursor: pointer;
        text-align:right;
        .burger-menu-piece {
            display: block;
            position: absolute;
            width: 30px;
            height: 3px;
            background: #E1B401;
            @include transform-origin(50%,50%);
            @include transition(transform,400ms,ease-out,0s);
            @include transition(background,400ms,ease-out,0s);
            left:0;
        }
        .burger-menu-piece:nth-child(1) {
            top: 0px;
            left: 0px;
        }
        .burger-menu-piece:nth-child(2) {
            top: 14px;
            opacity: 1;
            width:15px;
            right:0;
            left:inherit;
        }
        .burger-menu-piece:nth-child(3) {
            top: 28px;
            left:0px;
        }
    }
}
.burger-menu.actives {
  .burger-menu-piece:nth-child(1) {
      @include transform(translate3d(0,13px,0),rotate(45deg));
  }
  .burger-menu-piece:nth-child(2) {
      opacity: 0;
  }
  .burger-menu-piece:nth-child(3) {
      @include transform(translate3d(0,-15px,0),rotate(-45deg));
  }
}
//BURGER
//WŁĄCZ WYŁĄCZ BURGER KIEDY BĘDZIE OKREŚLONA SZEROKOŚĆ
@media (max-width: 1280px) {
    #menu {
        display:none!important;
    }
}
@media all and (min-width: 1281px) {
    .burger-menu {
        display: none;
    }
}
// MOBILNE MENU \/
.mobile-menu {
    height:inherit;
    @include position(fixed,0,-100%,0px,null,null);
    width: 100%;
    background:#fff;
    @include transition(all,0.6s,ease,0s);
    padding-top:78px;
    height:100vh;
    box-sizing:border-box;
    padding-top:90px;
    ul {
      text-align:left;
      width:100%;
      box-sizing:border-box;
      padding-left:20px;
        li{
          padding:20px 0;
          text-transform:uppercase;
            a {
              @include font(msbold,18px,#E1B401,1);

            }
        }
        .active {
            a {
              text-decoration: underline;
            }
        }
    }
}
.active {
    top:0!important;
}
// MOBILNE MENU //
