@charset 'UTF-8';

@import "perm/_all.scss";
/* Navbar */
@import "blocks/block_top.scss";
//@import "blocks/block_logo.scss";
@import "blocks/block_menu.scss";
//@import "blocks/block_social.scss";
@import "blocks/block_mobile_menu.scss";
/* site blocks */
//@import "blocks/block_banner.scss";
//@import "blocks/block_breadcrumbs.scss";
//@import "blocks/block_header.scss";
//@import "blocks/block_description.scss";
//@import "blocks/block_form.scss";
//@import "blocks/block_footer.scss";
//@import "blocks/block_project.scss";
//@import "blocks/block_cookies.scss";
//@import "blocks/block_cookies-box.scss";
//@import "blocks/block_cookies-accept.scss";
/* fancybox */
//@import "blocks/block_fancybox.scss";
/* site */
@import "site/_x.scss";
